






















































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters, mapMutations } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { IFundElement } from "@monorepo/catalog/src/views/FundView/types/fundElement";

interface ArchiveTreeItem extends IArchiveElement {
  funds: IFundElement[];
}

export default defineComponent({
  name: "DocumentTree",
  computed: {
    ...mapGetters("archiveCatalogView", ["infiniteId"]),
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations("archiveCatalogView", ["setSelectedIds", "setData"]),
    ...mapActions("archiveCatalogView", ["getArchiveHierarchy", "saveFundToNewArchive", "refreshScroll", "deleteElement"]),
    onDragStartFund(code: string) {
      this.draggedFundCode = code;
    },
    async deleteElementCb(id: string) {
      const isDeleted = await this.deleteElement({ ids: [id] });
      if (isDeleted) {
        this.refreshScroll();
      }
    },
    deleteArchive(id: string) {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: () => this.deleteElementCb(id),
          title: "Удалить архив?",
        })
      );
    },
    transferFundToArchive(code: string) {
      let archiveIndexFrom = this.treeItems.findIndex((item) => item.funds.some((fund) => fund.code === this.draggedFundCode));
      let archiveIndexTo = this.treeItems.findIndex((item) => item.code === code);
      if (archiveIndexFrom === archiveIndexTo) {
        return false;
      } else {
        let fund = this.treeItems[archiveIndexFrom].funds.find((item) => item.code === this.draggedFundCode);
        eventBus.$emit(
          MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
          formModalPayload(true, "DeleteModal", {
            cbOnDelete: () => {
              this.moveFund(code, fund?.id || "");
            },
            yesBtnTitle: "Да, перенести",
            title: "Перенести фонд в другой архив",
          })
        );
      }
      this.draggedFundCode = "";
    },
    async moveFund(archiveCode: string, fundId: string) {
      const isSave = await this.saveFundToNewArchive({ id: fundId, archive: { code: archiveCode } });
      if (!isSave) {
        showNotification("Ошибка переноса фонда");
      } else {
        showNotification("Фонд успешно перенесен", NOTIFICATION_STATUS.SUCCESS);
        this.refreshScroll();
      }
    },
    transferFundToFund(code: string) {
      let archiveIndexTo = this.treeItems.findIndex((item: ArchiveTreeItem) => item.funds.some((fund) => fund.code === code));
      let archiveCodeTo = this.treeItems[archiveIndexTo].code;
      this.transferFundToArchive(archiveCodeTo);
    },
    getTreeItems() {
      this.getArchiveHierarchy().then((result: ArchiveTreeItem[]) => {
        if (result) {
          this.treeItems = (result || []).reduce((result: ArchiveTreeItem[], item: ArchiveTreeItem) => {
            if (!item.isDeleted) {
              item.funds = item.funds.filter((fond) => !fond.isDeleted);
              result = result.concat(item);
            }
            return result;
          }, []);
        }
      });
    },
  },
  data() {
    return {
      draggedFundCode: "",
      treeItems: [] as ArchiveTreeItem[],
    };
  },
  watch: {
    infiniteId: {
      immediate: true,
      handler() {
        this.getTreeItems();
      },
    },
  },
});
